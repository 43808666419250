import React, {useContext, useState} from 'react';
import Form from "react-bootstrap/Form";
import Icon from "@reacticons/bootstrap-icons";
import {Context} from "../../index";
import {useIntl} from "react-intl";
import {InputGroup} from "react-bootstrap";


const ContentRowInput = (props) => {
    const {store} = useContext(Context);
    const handleChangeAdd = props.handleChangeAdd;
    const deleteContent = props.deleteContent;
    const index = props.index;
    const release= props.release;
    const row = props.row;



       return (
          <tr key={index}>

                               <td colSpan="5" className="content-row">
                                   <InputGroup>

                                       <span className="input-group-text"> Наименование груза </span>
                                       <Form.Control value={row.content_name}
                                                     onChange={e => handleChangeAdd(e, 'content_name', index)}
                                                     type="text" placeholder="" required
                                                     disabled={(release.status > 1) ? true : false}/>
                                       <span className="input-group-text">Артикул</span>
                                       <Form.Control value={row.content_artikul}
                                                     onChange={e => handleChangeAdd(e, 'content_artikul', index)}
                                                     type="text" placeholder="" required
                                                     disabled={(release.status > 1) ? true : false}/>
                                       <span className="input-group-text"> Количество мест </span>
                                       {(release.unload == 1) ?
                                           <Form.Control value={row.content_count}
                                                         onChange={e => handleChangeAdd(e, 'content_count', index)}
                                                         type="number" placeholder="" required
                                                         disabled={(release.status > 1) ? true : false}/> :
                                           <Form.Control value={row.content_count}
                                                         onChange={e => handleChangeAdd(e, 'content_count', index)}
                                                         type="number" placeholder=""
                                                         disabled={(release.status > 1) ? true : false}/>
                                       }
                                       <button type="button" className="input-group-text"
                                               onClick={e => deleteContent(index)}>Удалить строку
                                       </button>
                                   </InputGroup>
                               </td>
                           </tr>


        )


    }


export default ContentRowInput;