import React, {useContext, useEffect, useState} from 'react';
import ReleaseList from "../components/ReleaseList";
import $api from "../http";
import {useFetching} from "../hooks/useFetching";
import Loader from "../components/UI/Loader/Loader";
import {Context} from "../index";
import MovingsList2 from "../components/MovingsList2";
import ReleaseListDNS from "../components/ReleaseListDNS";



function Releases () {
    const {store} = useContext(Context);
    const [posts, setPosts] = useState(store.orders);
    const [limit, setLimit] = useState(store.limit)
    const [conin, setConin] = useState(0);
    const [conout, setConout] = useState(0);
    const [page, setPage] = useState(1);
    const [pageOrders, setPageorders] = useState([])
    const [totalPage, setTotalpage] = useState(Math.ceil(posts.length / store.limit))
    const getListOrders = (start, end) => {
        let array = [];
        for(start; start < end; start++){
            if(typeof posts[start] !== 'undefined'){
                array.push(posts[start])
            }

        }
        return array;
    }
    const [firstFetchOrders, isPageLoading] = useFetching(async () => {
        let  response;
        if(store.user.stock_id == 62){
               response = await $api.get(`/releasedns?l=${store.limit}`)
            } else {
               response = await $api.get(`/release?l=${store.limit}`)
            }
            if (response.status == 200) {
                setPageorders(response.data.release);
            }

    })
    const [fetchPosts, isPostsLoading] = useFetching(async (limit, page) => {
        let  response;
        if(store.user.stock_id == 62){
            response = await $api.get(`/releasedns`)
        } else {
            response = await $api.get('/release')
        }
        if(response.status == 200) {
            setPosts(response.data.release);
            store.setOrders(response.data.release);
            setConout(response.data.out);
            setConin(response.data.in);
        }

    })
    const handlePaginatorCount = (e) => {
        setLimit(e.target.value)
        setPage(1)
    }
    const changePage = (p) => {
        setPage(p)
    }

    useEffect(() => {
        firstFetchOrders();
        fetchPosts();
        const interval = setInterval(fetchPosts, 60000);
        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        let start = (page - 1) * limit
        let end = page * limit

        setPageorders(getListOrders(start,end))
        setTotalpage(Math.ceil(posts.length / limit))
    }, [page, posts,limit])

    const handleFilter = (fl) => {
        let _containers = store.orders

        if(fl.number != ''){

            _containers = _containers.filter(con => {
                if(con.containers !== null) {
                    return con.containers.toLowerCase().includes(fl.number.toLowerCase())
                }
            })
        }

        if(fl.release != ''){
            _containers = _containers.filter(container => {
                if(container.release !== null) {
                    return container.release.toLowerCase().includes(fl.release.toLowerCase())
                }
            })
        }

        if(fl.aplicant != ''){
            _containers = _containers.filter(container => {
                if(container.user !== null) {
                    return container.user.toLowerCase().includes(fl.aplicant.toLowerCase())
                }
            })
        }

        if(fl.type != ''){
            _containers = _containers.filter(container => {
                if (container.type_release == fl.type) return container;
            })
        }

        if(fl.status != ''){
            _containers = _containers.filter(container => {
                if (container.status == fl.status) return container;
            })
        }
        setPage(1)
        setPosts(_containers)
    }

    if(isPageLoading) {
        return (
            <Loader />
        );
    }  else {
        if(store.user.stock_id == 62) {
            return (
            <div className="App">

                <ReleaseListDNS handleFilter={handleFilter} orders={pageOrders} conin={conin} conout={conout}
                             changePage={changePage} page={page} totalPage={totalPage}
                             isPostsLoading={isPostsLoading} handlePaginatorCount={handlePaginatorCount}
                             limit={limit}/>
            </div>
            )
        } else {
            return (
                <div className="App">

                    <ReleaseList handleFilter={handleFilter} orders={pageOrders} conin={conin} conout={conout}
                                 changePage={changePage} page={page} totalPage={totalPage}
                                 isPostsLoading={isPostsLoading} handlePaginatorCount={handlePaginatorCount}
                                 limit={limit}/>
                </div>
            );
        }
    }

}

export default Releases;
